import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Carousel from "react-slick";
import Card from "components/Card/Card.jsx";
import Video from "components/Video/Video.jsx";

import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx";

import image1 from "assets/img/cases/osiris/osiris_01.jpg";
import image2 from "assets/img/cases/osiris/osiris_02.jpg";
import image3 from "assets/img/cases/osiris/osiris_03.jpg";
import image4 from "assets/img/cases/osiris/osiris_04.jpg";

import Nokia from "assets/img/logos/nokia-480x480.png";
import Telia from "assets/img/logos/telia-480x480.png";
import Intel from "assets/img/logos/intel-480x480.png";

class OsirisSection extends React.Component {
  render() {
    const { classes } = this.props;
    const carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">

            <div className={classes.title}>
              <h2>Real-time Quality Control in Production</h2>
              <h4>One of the world's first industrial 5G use cases from 2018 with Nokia, Telia, and Intel</h4>
              <br />
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Video",
                  tabIcon: undefined,
                  tabContent: (
                    <div>
                      <Video
                        videoSrcURL="https://www.youtube-nocookie.com/embed/E02Bqblce7E"
                        videoTitle="Official Music Video on YouTube"
                        aspectRatio="56.25%"
                      />
                    </div>
                  )
                },
                {
                  tabName: "Photos",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.section}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                            <Card carousel>
                              <Carousel {...carouselSettings}>
                                <div>
                                  <img src={image1} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image2} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image3} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image4} alt="Slide" className="slick-image" />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  )
                },
                {
                  tabName: "More Info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          The assembly process of a mobile phone network base station product
                          involves screwing large parts together using a power tool. From
                          quality assurance point of view, it is important that screwing is
                          performed according to instructions - the amount of screws and their
                          screwing order matter.
                        </p>
                        <p>
                          In 2017, Nokia approached Finwe and asked if we could develop a real-time
                          quality assurance solution based on video analytics. The goal was to detect
                          missing screws as well as wrong screwing order immediately when such errors
                          occur. This way the line operator can be alerted and he can fix the error
                          before proceeding with the assembly, which provides cost savings.
                        </p>
                        <p>
                          We familiarized ourselves with the product's assembly, performed data collection,
                          experimented with a few ideas and algorithms, and settled with marker-based
                          detection. We then designed and 3D-printed an add-on for the power tool for
                          tracking it in 6-DOF as well as detecting when its motor is running (screwing).
                          Using the same principle, we added a marker sticker to the aluminium screwing jig.
                          This allowed tracking their relative positions and detecting screwing routes.
                          Detecting the presence of a screw in each of the jig's screw holes was implemented
                          with machine learning algorithms and a set of training data. All in all, it took
                          only two months to get from the first factory visits to a working prototype.
                        </p>
                        <p>
                          After a successfull proof-of-concept project that produced an off-line solution,
                          the system was further improved in a follow-up project into an online version.
                          The video analytics module was installed to a server computer in Telia's
                          data center, which is located at 600 km distance from the factory. Nokia and Intel
                          5G prototype hardware was used for transmitting the video feed from the factory
                          to the analytics module and updating the recognition results back to the factory
                          into the line operator's tablet computer. With 5G network we achieved very low
                          latency. This was one of the first industrial 5G cases globally.
                        </p>
                        <p>
                          Later the setup has been upgraded to handle multiple assembly steps, easy
                          teaching of screw heads, and stand-alone operation via running the algorithms
                          locally in the line operator's tablet. In addition, we have added support for
                          Bosch intelligent screwing tools, which allow for example measuring and changing
                          applied torque based on screw type.
                        </p>
                        <p>
                          For demonstration and testing purposes, we have made a stand-alone demo kit that
                          fits in a small case. It has been a very popular hands-on type setup, demoed at
                          many trade shows globally.
                        </p>
                        <p>
                          <b>
                            To learn how video analytics can be used in your company, contact us and book an
                            e-meeting. Our offering includes on-site consultation for analysing potential
                            applications in co-operation with the customer.
                          </b>
                        </p>
                      </div>
                      <div className={classes.typo}>
                        <GridContainer>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto, classes.beforeTitle}>
                            <h4>Customer:</h4>
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={Nokia}
                              alt="Nokia"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto, classes.beforeTitle}>
                            <h4>Partners:</h4>
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={Telia}
                              alt="Telia"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={Intel}
                              alt="Intel"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridContainer>
                  )
                },
                {
                  tabName: "Links",
                  tabIcon: undefined,
                  tabContent: (
                    <div>
                      <p className={classes.textCenter}>
                        <a href="https://www.teliacompany.com/en/news/news-articles/2018/telia-nokia-and-intel-bring-5g-to-the-factory-floor/">2018-04-12 TELIA, NOKIA AND INTEL BRING 5G TO THE FACTORY FLOOR (by Telia)</a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://www.nokia.com/about-us/news/releases/2018/04/12/nokia-and-telia-conduct-industry-40-trial-in-finland-leveraging-low-latency-and-high-bandwidth-of-5g-technology/">2018-04-12 Nokia and Telia conduct Industry 4.0 trial in Finland leveraging low-latency and high-bandwidth of 5G technology (by Nokia)</a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://networkbuilders.intel.com/social-hub/video/telia-on-5g-and-video-analytics">2018-06-14 Telia on 5G and Video Analytics (by Intel)</a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://www.capacitymedia.com/articles/3800455/Nokia-Telia-and-Intel-carry-out-5G-manufacturing-trials">2018-04-13 Nokia, Telia and Intel carry out 5G manufacturing trials</a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://enterpriseiotinsights.com/20180412/channels/news/nokia-claims-first-5g-smart-factory-trial-tag40">2018-04-12 Nokia claims first “real-world” 5G smart factory trial with Telia and Intel</a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://www.marketwatch.com/press-release/wired-news---nokia-partners-with-telia-to-conduct-live-tests-in-finland-to-explore-potential-of-5g-for-industrial-applications-2018-04-16">2018-04-16 Wired News - Nokia Partners with Telia to Conduct Live Tests in Finland to Explore Potential of 5G for Industrial Applications</a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://www.sdxcentral.com/articles/news/finlands-telia-tests-5g-at-a-nokia-base-station-factory/2018/04/">2018-04-12 Finland’s Telia Tests 5G at a Nokia Base Station Factory</a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://industryeurope.com/telia-nokia-and-intel-trial-5g-in-finland/">2018-04-13 Telia, Nokia And Intel Trial 5G In Finland</a>
                      </p>
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultSectionStyle)(OsirisSection);
